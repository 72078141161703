<template>
  <div>
    <NavbarCommon :title="title"></NavbarCommon>
    <Signin :signin="signin" v-if="signin.uid"></Signin>
    <van-loading v-else size="6.667vw" vertical>加载中...</van-loading>
  </div>
</template>

<script>
import NavbarCommon from '@/components/common/NavbarCommon'
import Signin from '@/components/clockin/Signin'
export default {
  data () {
    return {
      datamodel: {
        uid: '',
        clockin: ''
      }
    }
  },
  components: {
    NavbarCommon, Signin
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
  },
  mounted () {
    this.datamodel.clockin = localStorage.getItem('clockid')
    this.datamodel.uid = localStorage.getItem('uid')
    // 请求课程打卡日历
    this.$store.dispatch('getClockinAction', this.datamodel)
  },
  computed: {
    signin () {
      let signin = this.$store.state.clockin.signin
      if (signin.uid > 0) {
        return signin
      } else {
        signin = localStorage.getItem('clockinList')
      }

      if (signin === '') { signin = null }
      var signinArr = []
      if (signin !== null) {
        signinArr = JSON.parse(signin)
      }
      return signinArr
    },
    title () {
      return localStorage.getItem('clockinname')
    }
  },
  methods: {

  },
  beforeUnmount () {
    this.$store.commit('hideShowTabbar', true)
  }
}
</script>

<style lang='scss' scoped>
.inline {
  display: flex;
  justify-content: space-between;
}
ul,
li {
  padding: 0vw;
  margin: 0vw;
  list-style: none;
}
ul {
  background-color: rgb(27, 8, 9);
  text-align: left;
  li {
    height: 16.667vw;
    padding: 8.333vw;
    color: #fff;
    .wordsFromHeSang {
      font-size: 3.333vw;
      color: yellow;
      padding-left: 5.556vw;
      padding-top: 2.778vw;
    }
  }
}
</style>
