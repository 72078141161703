<template>
  <div class="bdy">
    <div class="dakadate">&nbsp;{{ this.year }}年 {{ this.month }}月</div>
    <div v-html="signin.calendar"></div>
    <van-divider
      :style="{
        color: '#1989fa',
        borderColor: '#1989fa',
        padding: '0 4.444vw',
      }"
    >
      打卡情况
    </van-divider>
    <div class="dakainfo">
      <img src="@/assets/dadkicon.png" width="20.002px" /> 累计打卡
      {{ signin.allDays }} 天，
      {{ signin.allNum }}
      {{ this.unit }}
    </div>
    <div class="dakainfo">
      <img src="@/assets/dadkicon.png" width="20.002px" /> 本月打卡
      {{ signin.monthDays }} 天，
      {{ signin.monthNum }}
      {{ this.unit }}
    </div>
    <div class="dakainfo" v-if="signin.todayNum > 0">
      <img src="@/assets/dadkicon.png" width="20.002px" /> 今天打卡
      {{ signin.todayNum }}
      {{ this.unit }}
    </div>
    <div class="Nodakainfo" v-else>
      <img src="@/assets/dadkicon.png" width="20.002px" /> 今天未打卡
    </div>
    <van-field
      v-model="daModel.num"
      center
      clearable
      label="打卡数量"
      type="digit"
      placeholder="请输入打卡数量"
    >
      <template #button>
        <van-button size="small" type="primary" @click="dk()"
          >点击打卡</van-button
        >
      </template>
    </van-field>
    <van-divider
      :style="{
        color: '#1989fa',
        borderColor: '#1989fa',
        padding: '0 4.444vw',
      }"
    >
      学修内容
    </van-divider>
    <div v-html="signin.clockproject.description" class="content"></div>
  </div>
</template>

<script>
export default ({
  props: ['signin'],
  data () {
    return {
      year: '',
      month: '',
      daModel: {
        num: '',
        uid: '',
        clockid: ''
      }
    }
  },
  mounted () {
    var date = new Date()
    this.year = date.getFullYear()
    this.month = date.getMonth() + 1
  },
  methods: {
    getClockinOfday (year, month, day, num) {
      this.$msg(year + '年' + month + '月' + day + '日 打卡' + num + this.unit)
    },
    dk () {
      this.daModel.uid = localStorage.getItem('uid')
      this.daModel.clockid = this.$store.state.clockin.signin.clockid
      this.daModel.key = Math.random()
      if (!this.daModel.num) {
        this.$msg('请输入打卡数量')
        return
      }
      if (!this.isRealNum(this.daModel.num)) {
        this.$msg('请输入数字')
        return
      }
      this.$store.dispatch('getDkAction', this.daModel)
      this.daModel.num = ''
    },
    isRealNum (val) {
      if (val === '' || val == null) {
        return false
      }
      if (!isNaN(val)) {
        return true
      } else { return false }
    }
  },
  computed: {
    unit () {
      return this.$store.state.clockin.signin.clockproject.unit
    }
  }
})
</script>

<style lang="scss" scoped>
::v-deep .isdaka {
  width: 9.722vw;
  height: 6.944vw;
  border-radius: 6.944vw;
  background-color: #863407;
  padding-top: 2.778vw;
  color: white;
  font-size: 3.611vw;
}
::v-deep .noisdaka {
  width: 9.722vw;
  height: 6.944vw;
  border-radius: 6.944vw;
  border: 0.278vw solid #c78e70;
  padding-top: 2.778vw;
  color: #863407;
  font-size: 3.611vw;
}
::v-deep .isdakanext {
  width: 9.722vw;
  height: 6.944vw;
  padding-top: 2.778vw;
  color: #c67605;
  font-size: 3.611vw;
}
::v-deep .week {
  width: 9.722vw;
  height: 9.722vw;
  padding-top: 1.944vw;
  color: #c67605;
  font-size: 3.611vw;
}
::v-deep .todayNodak {
  width: 9.722vw;
  height: 6.944vw;
  color: blue;
  border-radius: 6.944vw;
  border: 0.278vw solid #c78e70;
  padding-top: 2.778vw;
}
::v-deep .todayIsdak {
  width: 9.722vw;
  height: 6.944vw;
  color: blue;
  border-radius: 6.944vw;
  background-color: #9e9a98;
  padding-top: 2.778vw;
}
.dakadate {
  width: 100%;
  height: 8.333vw;
  font-size: 4.444vw;
  padding-top: 2.778vw;
  //   padding-left: 2.778vw;
  background-color: #863407;
  color: white;
  font-size: 3.889vw;
}
::v-deep .nongli {
  width: 8.333vw;
  height: 5.556vw;
  color: #aaa;
  font-size: 3.611vw;
}
.dakainfo {
  padding: 0.833vw;
  padding-left: 2.778vw;
  color: #863407;
  font-size: 3.611vw;
  line-height: 5.556vw;
}
.Nodakainfo {
  padding: 0.833vw;
  padding-left: 2.778vw;
  color: red;
  font-size: 3.611vw;
  line-height: 5.556vw;
}
.content {
  padding-left: 1.389vw;
  padding-right: 1.389vw;
  font-size: 3.611vw;
}
</style>
